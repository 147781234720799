import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
// import ButtonGhost from "../Button/ButtonGhost";
import image from "./Start Seeing The Big Picture.svg"
const CTA = ({
  heading,
  headingLevel,
  ctaLink,

  className,
}) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <div className="container">
      <section
        className={`rounded-4xl  px-8 py-18 text-center md:p-14 md:text-left ${
          className || ""
        }`}
        style={{
          background: "linear-gradient(270deg, #E2DAFE 0%, #E9EFFD 100%)",
        }}
      >
        <div className="justify-between flex-col-reverse md:flex-row-reverse flex md:space-x-10 lg:space-x-20">
          <div className="mb-6 max-w-[720px] md:order-1">
            <HeadingTag className="mb-4 text-primary-900">
              {heading || ["Custom Services"]}
            </HeadingTag>
            <p className="text-xl text-primary-900">
              Work with our team of experts to streamline and integrate your tech stack.
            </p>
            <ButtonSolid
              altStyle={2}
              modal="modal-contact"
              text="Schedule a Tech Consult"
            />
          </div>
          <div className="mx-auto pb-2 md:pb-0">
            <img 
              className=" mx-auto md:max-w-[90%]"
              src={image}
              alt="Integration & Automation Reports"
            />
          </div>

        </div>
      </section>
    </div>
  );
};

export default CTA;
