import React, { useState } from "react";
import { graphql } from "gatsby";
import { Switch } from "@headlessui/react";
// import styled from "@emotion/styled";
import ReactTooltip from 'react-tooltip'
// import { mapEdgesToNodes } from "../lib/helpers";

// import tw from "twin.macro";
// import Link from "gatsby-plugin-transition-link";
import Testimonials from "../components/Repeating/Testimonials"
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTAV3";
// import Checkmark from "../images/global/checkmark.svg";
import ButtonSolid from "../components/Button/ButtonSolid";

// const StyledContent = styled.div`
//   ul,
//   ol {
//     ${tw`mb-8 space-y-2.5`}
//     li {
//       ${tw`flex items-baseline`}
//       &:before {
//         content: "\\f00c";
//         font-family: "Font Awesome 5 Pro";
//         ${tw`mr-3 text-xs font-bold text-white bg-primary-700 min-w-[18px] h-[18px] flex items-center justify-center rounded-full`}
//       }
//     }
//   }
// `;


const cost = {
  starter: [
    {
      month: "$30", year: "$288", orginalPrice: "$360"
    },

  ],
  grow: [
    {
      month: "$75", year: "$720", orginalPrice: "$900"
    },

  ],
  platform: [
    {
      month: "$250", year: "$2,400", orginalPrice: "$3,000"
    },

  ],
};
const pirceDropdown = {
  starter: [

    {
      name: "Access to our library of support articles",
    },

    {
      name: "Customer support in real time",
    },

    {
      name: "Hundreds of smart filters available with every integration",
    },

  ],
  grow: [
 
    {
      name: "Free account set up",
    },

    {
      name: "Support with onboarding and activation of integrations",
    },
    {
      name: "Access to analytics on how your integration is performing",
    },
    {
      name: "Custom admin permission - control your users",
    },

  ],
  platform: [
    {
      name: "Priority customer support - 5X faster response",
    },

    {
      name: "1 hour of free customization",
    },
    {
      name: "Monthly tech stack review with our Account Executive (tech experts)",
    },
    {
      name: "Access to our new integrations before general public",
    },
    {
      name: "Exclusive custom service prices",
    },
    {
      name: "Access to client-driven LIVE monthly mastermind ",
    },

  ],
};
const Page = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  
  const [navDropdownOpen1, setnavDropdownOpen1] = useState(false);
  const navClickHandler1 = () => {
    setnavDropdownOpen1(!navDropdownOpen1);
  };

  const [navDropdownOpen2, setnavDropdownOpen2] = useState(false);
  const navClickHandler2 = () => {
    setnavDropdownOpen2(!navDropdownOpen2);
  };

  const [navDropdownOpen3, setnavDropdownOpen3] = useState(false);
  const navClickHandler3 = () => {
    setnavDropdownOpen3(!navDropdownOpen3);
  };




  const tier = {
    starter: [
      {
        name: "Starter"
      },

    ],
    grow: [
      {
        name: "Grow"
      },

    ],
    platform: [
      {
        name: "Platform (Unlimited)"
      },

    ],
  };

  return (
    <Layout>
      <SearchEngineOptimization
        title="Pricing | Real Synch | Real Estate Automation Software"
        description="Learn about pricing for Real Synch here. We easily integrate and automate your real estate team's workflow through our groundbreaking software."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      {/* HERO  */}
      <section className="pt-0  bg-white md:pt-16 md:pb-0 ">
        <div className="lg:container">
          <header className="mx-auto text-center  ">
            <h1 className="text-typography-heading">
              Time-Saving Integrations Start Here
            </h1>
          </header>
          {/* TRY US FOR FREE SECTION */}
          <div
            className="my-4 hidden md:block lg:container bg-transparent lg:bg-primary-900 rounded-xl">
            <div className="flex justify-center lg:block">
              <div className="grid  lg:grid-cols-10 h-full rounded-[12px] bg-primary-900 lg:bg-transparent">
                <div className="lg:col-start-1 lg:col-end-3 h-full flex flex-col items-center justify-center md:border-r-solid md:border-r-[1px] md:border-r-white/30 
               md:border-b-0">
                  <div className="text-center w-full py-4 xl:px-6">
                    <h4 className="mb-3.5 text-white">Try Us For Free</h4>
                    <p className="text-white text-[20px] font-extrabold">
                      $0 <span className="text-gray-50/50 text-base font-normal">/mo</span>
                    </p>
                    <span> <ButtonSolid href={"https://app.realsynch.com/register?plan=clientfree&version=5"} text="Start for free" />
                    </span>
                  </div>
                </div>
                <div className="lg:col-span-7  lg:col-start-3 flex lg:gap-2 h-full  flex-col border md:border-0 px-8 lg:pr-0 items-baseline">

                  <div className="gap-y-6 grid mt-10">
                    <div className="w-full text-left">
                      <p className="uppercase font-bold mb-0 text-gray/60">What’s included</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                      <div className="h-full ">
                        <ul className="styled-list-checkmark-alt-blue pt-2 space-y-4">
                          <li className="before:text-[#55CAFC]">
                            <ReactTooltip arrowColor="transparent" className="tooltip text-left font-bold font-body common-shadow" textColor="#0100f3A" backgroundColor="#fff" id="free2"> {"A task is any activity that is triggered to move data from one system to another"}</ReactTooltip>
                            <span className=" pr-2 font-body text-white">Up to 100 tasks per month</span> <span data-tip data-for="free2" className="fas fa-circle-info text-[#55CAFC]"></span>
                          </li>
                          <li className="before:text-[#55CAFC]">
                            <span className="font-body text-white">Unlimited real estate integrations</span>
                          </li>
                          <li className="before:text-[#55CAFC]">
                            <span className="font-body text-white">Unlimited users</span>
                          </li>
                        </ul>
                      </div>
                      <div className="w-full h-full">
                        {/* <p className="uppercase mb-0">What’s included</p> */}
                        <ul className="styled-list-checkmark-alt-blue pt-2 space-y-4">
                          <li className="before:text-[#55CAFC]">
                            <span className=" pr-2 font-body text-white">Connect your CRM in real time</span>
                          </li>
                          <li className="before:text-[#55CAFC]">
                            <span className="font-body text-white">Connect all your systems in your tech stack</span>
                          </li>
                          <li className="before:text-[#55CAFC]">
                            <span className="font-body text-white">Chat Support Available </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div>

                </div>
              </div>
            </div>

          </div>
          {/* END OF TRY US FOR FREE */}
        </div>
      </section>
      {/*  MOBILE LAYOUT */}
      <section className="container px-4 md:hidden">
        <div className="bg-primary-900 rounded-[12px] ">
          <div className="gap-y-6  gap-x-10 grid-cols-1  lg:grid-cols-3 grid">
            {/* STARTER */}
            <div className="flex justify-center overflow-visible md:grid rounded-b-[12px] border-t-[15px] border-t-typography-heading rounded-t-[12px] common-shadow border-gray-700/20">
              <div className="w-full">
                <div className="items-center  flex-col flex h-full">
                  <div className="lg:col-start-1 lg:col-end-3 h-full flex flex-col items-center justify-center md:border-r-solid md:border-r-[1px] md:border-r-white/30 
               md:border-b-0">
                    <div className="text-center w-full py-4 xl:px-6">
                      <h4 data-tip="try" className="mb-3.5 text-white">Try Us For Free</h4>
                      <p className="text-white text-[20px] font-extrabold">
                        $0 <span className="text-gray-50/50 text-base font-normal">/mo</span>
                      </p>
                      <div className="w-full px-6">
                        <div className="py-4 text-center">
                          <ButtonSolid modal="modal-contact" text="Start for Free" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-[1px] w-full bg-white/30"></div>
                  <div className=" w-full mb-2\.5 pl-4 pr-2 text-6xl font-bold text-typography-heading max-h-[45px]">
                  </div>
                  <div className=" w-full pl-4 grid mt-10  ">
                    <div className="w-full text-left">
                      <p className="uppercase font-base text-white/60">What’s included</p>
                    </div>
                    <div className="grid gap-y-4 grid-cols-1 lg:grid-cols-2">
                      <div className="h-full w-full mb-1 pb-0">
                        <ul className=" mb-0 styled-list-checkmark-alt-blue pt-2 space-y-4">
                          <li className="">
                            <ReactTooltip arrowColor="transparent" className="tooltip text-left font-bold font-body common-shadow" textColor="#0100f3A" backgroundColor="#fff" id="free2"> {"A task is any activity that is triggered to move data from one system to another"}</ReactTooltip>
                            <span className=" pr-2 font-body text-white">Up to 100 tasks per month</span> <span data-tip data-for="free2" className="fas fa-circle-info text-typography-heading"></span>
                          </li>
                          <li className="">
                            <span className="font-body text-white">Unlimited real estate integrations</span>
                          </li>
                          <li className="">
                            <span className="font-body text-white">Unlimited users</span>
                          </li>
                        </ul>
                      </div>
                      <div className="pt-0 mt-0 w-full h-full">
                        {/* <p className="uppercase mb-0">What’s included</p> */}
                        <ul className="styled-list-checkmark-alt-blue  space-y-4">
                          <li className="">
                            <span className=" pr-2 font-body text-white">Connect your CRM in real time</span>
                          </li>
                          <li className="">
                            <span className="font-body text-white">Connect all your systems in your tech stack</span>
                          </li>
                          <li className="">
                            <span className="font-body text-white">Chat Support Available </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END OF PLATFORM */}
          </div>
        </div>
        {/* END OF DESKTOP */}
      </section>
      {/*  MOBILE LAYOUT */}

      {/* CTA w/switch */}
      <section>
        <div className="container mb-0">
          <div className="mx-auto my-10 max-w-[800px] text-center ">
            <h2>Premium Plans</h2>
            <p>
              We have pricing plans to support all levels—whether you’re a small real estate team looking for a starter solution, a growing firm, or a large realty company with many moving parts.
            </p>
            <div className=" my-0 py-4 flex items-center justify-center space-x-3">

              <div className="text-sm font-extrabold uppercase text-typography-heading">
                Monthly
              </div>
              <Switch
                checked={isMonthly}
                onChange={setIsMonthly}
                className={`${isMonthly}
                  relative inline-flex h-6 w-[44px] flex-shrink-0 cursor-pointer rounded-xl bg-gradient-to-br from-primary-400 to-primary-700`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${isMonthly ? "translate-x-0.5" : "translate-x-[22px]"
                    }
                    pointer-events-none inline-block h-5 w-5 translate-y-0.5 transform rounded-full bg-white shadow-5xl ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <div className="text-sm font-extrabold uppercase text-typography-heading">
                Annual <div className={isMonthly ? " normal" : "bold"}>
                  <div className="relative bottom-8  md:left-12 left-10">
                    <span className="inline-block w-[100px] md:min-w-[95px] absolute p-2 border-[#83B813] text-[#83B813] border rounded font-body ">SAVE 20%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* END OF CTA w/switch */}

      <section className="pb-10 md:pb-[inheirt]">
        {/* PRICE CARDS */}
        <div className=" container my-5">
          <div className="gap-y-8 px-4 gap-x-6 grid-cols-1  mx-auto  lg:grid-cols-3 grid">

            {/* STARTER */}
            <div className="flex md:grid flex-col rounded-b-[12px] common-shadow border-t-[15px] border-t-typography-heading rounded-t-[12px] border-gray-700/20">
              <div className="w-full">
                <div className="items-center  flex-col flex   ">
                  {/* TIER NAME */}
                  <div className="w-full max-h-[44px] mb-4 pt-4 px-4 block text-lg  text-typography-heading">
                    {tier.starter.map((item) => (
                      <div
                        className="pr-2 text-xl text-typography-heading font-semibold"
                      >
                        <span> {item.name}</span>
                      </div>
                    ))}
                  </div>
                  {/* END OF TEIR */}
                  {/* AMOUNT MO/YR */}
                  <div className=" w-full mb-2\.5 pl-4 pr-2 text-6xl font-bold text-typography-heading max-h-[45px]">
                    <div className=" w-full pb-4 pt-2">
                      <div className="grid-cols-12 grid">
                        <div className="col-span-5">
                          <p className="text-5xl leading-[30px] text-center lg:text-left font-extrabold text-typography-heading ">
                            <span className="inline-block">
                              {cost.starter.map((item) => (
                                <p key={item.month}>
                                  <span className={`before:w-[80%] before:block before:z-[5] before:top-[15px] mx-auto before:h-[3px] h-full before:absolute before:left-[1.25rem] before:-right-[.75rem] before:-skew-y-[0deg]  relative inline-block ${isMonthly ? "before:bg-transparent" : "before:bg-primary-900"}`}> {isMonthly ? <span className="relative before:z-[1]"> {item.month} </span> : <span className="relative"> {item.orginalPrice} </span>}</span>
                                </p>
                              ))}
                            </span>
                            <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-6">
                          <div className={`w-full ${isMonthly ? "opacity-0" : "opacity-1"
                            }`}>
                            <p className="text-5xl leading-[30px] font-extrabold text-primary-700 ">
                              <span className="inline-block">
                                {cost.starter.map((item) => (
                                  <p key={item.month}>
                                    <span className="pl-4 md:pl-[inherit]" > {isMonthly ? `${item.month}` : `${item.year}`}</span>
                                  </p>
                                ))}
                              </span>
                              <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                              </span>
                            </p>
                            </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b-gray-300 h-[1px]  border-b-[1px] pt-4 pb-2 w-full"></div>
                  {/* END OF AMOUNT MO/YR */}
                  {/* WHATS INCLUDED */}
                  <div className="w-full  py-[8px]">
                    <p className="text-sm leading-4 tracking-wide uppercase mb-0 p-4 font-medium text-gray-900">What’s included</p>
                  </div>
                  {/* END OF WHATS INCLUDED */}
                  {/* DROPDOWN 1 */}
                  <div className="w-full p-2 relative">

                    <ul className="flex justify-around">
                      <li className="w-full text-left relative ">
                        <button
                          className={`transition-none border-x-[1px] border-t-[1px] rounded-t-[8px] py-2 px-4 relative font-body font-normal w-full text-typography-heading no-underline hover:text-primary-700 overflow-visible  ${navDropdownOpen1 ? "border-t-[1px] bg-white border-b-[1px] border-b-transparent" : "border-y-[1px] rounded-[8px]"
                            }`}
                          onClick={navClickHandler1}
                        >
                          <div className="w-full flex  justify-between align-middle">
                            <p className="mb-0"><span className="fa pr-2 !leading-[inherit] fa-check text-[#673DEC]/80"></span>
                              <span className=" mt-2 pr-2 text-[#525F7F] font-semibold">Everything in Free +</span>
                            </p>
                            <i
                              className={`fas text-sm ml-1 transform pl-4 !leading-[inherit] transition-all duration-300 ease-linear ${navDropdownOpen1 ? "fa-chevron-up" : "fa-chevron-down"
                                }`}>

                            </i>
                          </div>

                        </button>
                        <ul
                          className={`styled-list-checkmark-alt lg:grid lg:absolute border-[1px] block rounded-b-[8px]  left-0 py-4 px-2 mx-auto  drop-shadow-2xl transform  bg-white shadow-2x; text-left transition-all duration-300 ease-linear  ${navDropdownOpen1
                            ? "visible translate-y-0 opacity-100"
                            : "invisible -translate-y-4 opacity-0 hidden"
                            }`}
                        >
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Unlimited real estate integrations</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Unlimited users</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Connect your CRM in real time</span>
                          </li> <li> <span className="pr-2 text-[#525F7F]  font-semibold">Connect all your systems in your tech stack</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Chat support available</span> </li>

                        </ul>
                        <ReactTooltip arrowColor="transparent" className=" common-shadow tooltip text-typography-heading" textColor="#01003A" backgroundColor="#fff" id="free">{"A task is any activity that is triggered to move data from one system to another."}</ReactTooltip>
                      </li>
                    </ul>

                  </div>
                  {/*END OF DROPDOWN 1 */}
                </div>
                <div className="w-full h-full">
                  <ul className="styled-list-checkmark-alt font-semibold p-4 space-y-4">
                    <li className="">
                      <span className=" mt-2 pr-2 text-[#525F7F] ">Up to 500 tasks per month</span> <span data-for="starter" data-tip className="fas fa-circle-info text-[#673DEC]/80"></span>
                      <ReactTooltip arrowColor="transparent" className="tooltip text-typography-heading" textColor="#fff" backgroundColor="#01003A" id="starter">{"If you exceed your task limit for the month you will be notified and your account will move to the Grow Plan to continue service"}</ReactTooltip>
                    </li>
                    {pirceDropdown.starter.map((item) => (
                      <li key={item.name}>
                        <div
                          className="pr-2 font-semibold text-[#525F7F] "
                        >
                          <span> {item.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full flex-col flex justify-end">
                <div className="p-4">
                  <a className="text-white  text-center rounded-xl py-3 block  cursor-pointer no-underline hover:shadow-xl transiton-all ease-linear transition hover:text-white" href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=starter&version=5"
                      : "https://app.realsynch.com/register?plan=annualclientstarter&version=5"
                  }
                    outboundLink={true}
                    text="Choose Advanced"
                    style={{
                      background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #673DEC",
                    }}>
                    Sign up Now
                  </a>
                </div>
              </div>
            </div>
            {/* END OF STARTER */}

            {/* GROW */}
            <div className="flex md:grid flex-col rounded-b-[12px] common-shadow border-t-[15px] border-t-typography-heading rounded-t-[12px] border-gray-700/20">
              <div className="w-full">
                <div className="items-center  flex-col flex   ">
                  {/* TIER NAME */}
                  <div className="w-full max-h-[44px] mb-4 pt-4 px-4 block text-lg  text-typography-heading">
                    {tier.grow.map((item) => (
                      <div
                        className="pr-2 text-xl text-typography-heading font-semibold"
                      >
                        <span> {item.name}</span>
                      </div>
                    ))}
                  </div> 
                  {/* END OF TEIR */}
                  {/* AMOUNT MO/YR */}
                  <div className=" w-full mb-2\.5 pl-4 pr-2 text-6xl font-bold text-typography-heading max-h-[45px]">
                    <div className=" w-full pb-4 pt-2">
                      <div className="grid-cols-12 grid">
                        <div className="col-span-5">
                          <p className="text-5xl leading-[30px] text-center lg:text-left font-extrabold text-typography-heading ">
                            <span className="inline-block">
                              {cost.grow.map((item) => (
                                <p key={item.month}>
                                  <span className={`before:w-[80%] before:block before:z-[5] before:top-[15px] mx-auto before:h-[3px] h-full before:absolute before:left-[1.25rem] before:-right-[.75rem] before:-skew-y-[0deg]  relative inline-block ${isMonthly ? "before:bg-transparent" : "before:bg-primary-900"}`}> {isMonthly ? <span className="relative before:z-[1]"> {item.month} </span> : <span className="relative"> {item.orginalPrice} </span>}</span>
                                </p>
                              ))}
                            </span>
                            <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-6">
                          <div className={`w-full ${isMonthly ? "opacity-0" : "opacity-1"
                            }`}>
                            <p className="text-5xl leading-[30px] font-extrabold text-primary-700 ">
                              <span className="inline-block">
                                {cost.grow.map((item) => (
                                  <p key={item.month}>
                                    <span className="pl-4 md:pl-[inherit]"> {isMonthly ? `${item.month}` : `${item.year}`}</span>
                                  </p>
                                ))}
                              </span>
                              <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                              </span>
                            </p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b-gray-300 h-[1px]  border-b-[1px] pt-4 pb-2 w-full"></div>
                  {/* END OF AMOUNT MO/YR */}
                  {/* WHATS INCLUDED */}
                  <div className="w-full  py-[8px]">
                    <p className="text-sm leading-4 tracking-wide uppercase mb-0 p-4 font-medium text-gray-900">What’s included</p>
                  </div>
                  {/* END OF WHATS INCLUDED */}
                  {/* DROPDOWN 2 */}
                  <div className="w-full p-2 relative">

                    <ul className="flex justify-around">
                      <li className="w-full text-left relative ">
                        <button
                          className={`transition-none border-x-[1px] border-t-[1px] rounded-t-[8px] py-2 px-4 relative font-body font-normal w-full text-typography-heading no-underline hover:text-primary-700 overflow-visible  ${navDropdownOpen2 ? "border-t-[1px] bg-white border-b-[1px] border-b-transparent" : "border-y-[1px] rounded-[8px]"
                            }`}
                          onClick={navClickHandler2}
                        >
                          <div className="w-full flex  justify-between align-middle">
                            <p className="mb-0"><span className="fa pr-2 !leading-[inherit] fa-check text-[#673DEC]/80"></span>
                              <span className=" mt-2 pr-2 text-[#525F7F] font-semibold">Everything in Starter +</span>
                            </p>
                            <i
                              className={`fas text-sm ml-1 transform pl-4 !leading-[inherit] transition-all duration-300 ease-linear ${navDropdownOpen2 ? "fa-chevron-up" : "fa-chevron-down"
                                }`}>

                            </i>
                          </div>

                        </button>
                        <ul
                          className={`styled-list-checkmark-alt lg:grid lg:absolute border-[1px] block rounded-b-[8px]  left-0 py-4 px-2 mx-auto  drop-shadow-2xl transform  bg-white shadow-2x; text-left transition-all duration-300 ease-linear  ${navDropdownOpen2
                            ? "visible translate-y-0 opacity-100"
                            : "invisible -translate-y-4 opacity-0 hidden"
                            }`}
                        >
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Access to our library of support articles</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Customer support in real time</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Hundreds of smart filters available with every integration</span> </li>

                        </ul>
                        <ReactTooltip arrowColor="transparent" className=" common-shadow tooltip text-typography-heading" textColor="#01003A" backgroundColor="#fff" id="free">{"A task is any activity that is triggered to move data from one system to another."}</ReactTooltip>
                      </li>
                    </ul>

                  </div>
                  {/*END OF DROPDOWN 1 */}
                </div>
                <div className="w-full h-full">
                  <ul className="styled-list-checkmark-alt font-semibold p-4 space-y-4">
                    <li className="">
                      <span className=" mt-2 pr-2 text-[#525F7F] ">Up to 1k tasks per month</span> <span data-for="grow" data-tip className="fas fa-circle-info text-[#673DEC]/80"></span>
                      <ReactTooltip arrowColor="transparent" className="tooltip text-typography-heading" textColor="#fff" backgroundColor="#01003A" id="grow">{"If you exceed your task limit for the month you will be notified and your account will move to the Platform Plan to continue service"}</ReactTooltip>
                    </li>
                    {pirceDropdown.grow.map((item) => (
                      <li key={item.name}>
                        <div
                          className="pr-2 font-semibold text-[#525F7F] "
                        >
                          <span> {item.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full flex-col flex justify-end">
                <div className="p-4">
                  <a className="text-white  text-center rounded-xl py-3 block  cursor-pointer no-underline hover:shadow-xl transiton-all ease-linear transition hover:text-white" href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=grow&version=5"
                      : "https://app.realsynch.com/register?plan=annualgrow&version=5"
                  }
                    outboundLink={true}
                    text="Choose Advanced"
                    style={{
                      background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #673DEC",
                    }}>
                    Sign up Now
                  </a>
                </div>
              </div>
            </div>
            {/* END OF GROW */}

            {/* PLATFORM */}
            <div className="flex md:grid flex-col rounded-b-[12px] common-shadow border-t-[15px] border-t-typography-heading rounded-t-[12px] border-gray-700/20">
              <div className="w-full">
                <div className="items-center  flex-col flex   ">
                  {/* TIER NAME */}
                  <div className="w-full max-h-[44px] mb-4 pt-4 px-4 block text-lg  text-typography-heading">
                    {tier.platform.map((item) => (
                      <div
                        className="pr-2 text-xl text-typography-heading font-semibold"
                      >
                        <span> {item.name}</span>
                      </div>
                    ))}
                  </div>
                  {/* END OF TEIR */}
                  {/* AMOUNT MO/YR */}
                  <div className=" w-full mb-2\.5 pl-4 pr-2 text-6xl font-bold text-typography-heading max-h-[45px]">
                    <div className=" w-full pb-4 pt-2">
                      <div className="grid-cols-12 grid">
                        <div className="col-span-6">
                          <p className="text-5xl leading-[30px] text-center lg:text-left font-extrabold text-typography-heading ">
                            <span className="inline-block">
                              {cost.platform.map((item) => (
                                <p key={item.month}>
                                  <span className={`before:w-[80%] before:block before:z-[5] before:top-[15px] mx-auto before:h-[3px] h-full before:absolute before:left-[1.25rem] before:-right-[.75rem] before:-skew-y-[0deg]  relative inline-block ${isMonthly ? "before:bg-transparent" : "before:bg-primary-900"}`}> {isMonthly ? <span className="relative before:z-[1]"> {item.month} </span> : <span className="relative"> {item.orginalPrice} </span>}</span>
                                </p>
                              ))}
                            </span>
                            <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                            </span>
                          </p>
                        </div>
                        <div className="col-span-6">
                          <div className={`w-full ${isMonthly ? "opacity-0" : "opacity-1"
                            }`}>
                            <p className="text-5xl leading-[30px] font-extrabold text-primary-700 ">
                              <span className="inline-block">
                                {cost.platform.map((item) => (
                                  <p key={item.month}>
                                    <span className="pl-4 md:pl-[inherit]" > {isMonthly ? `${item.month}` : `${item.year}`}</span>
                                  </p>
                                ))}
                              </span>
                              <span className=" inline-block font-base leading-6 font-medium text-sm text-gray-600/70  "> {isMonthly ? "/mo" : "/yr"}
                              </span>
                            </p>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b-gray-300 h-[1px]  border-b-[1px] pt-4 pb-2 w-full"></div>
                  {/* END OF AMOUNT MO/YR */}
                  {/* WHATS INCLUDED */}
                  <div className="w-full  py-[8px]">
                    <p className="text-sm leading-4 tracking-wide uppercase mb-0 p-4 font-medium text-gray-900">What’s included</p>
                  </div>
                  {/* END OF WHATS INCLUDED */}
                  {/* DROPDOWN 3 */}
                  <div className="w-full p-2 relative">

                    <ul className="flex justify-around">
                      <li className="w-full text-left relative ">
                        <button
                          className={`transition-none border-x-[1px] border-t-[1px] rounded-t-[8px] py-2 px-4 relative font-body font-normal w-full text-typography-heading no-underline hover:text-primary-700 overflow-visible  ${navDropdownOpen3 ? "border-t-[1px] bg-white border-b-[1px] border-b-transparent" : "border-y-[1px] rounded-[8px]"
                            }`}
                          onClick={navClickHandler3}
                        >
                          <div className="w-full flex  justify-between align-middle">
                            <p className="mb-0"><span className="fa pr-2 !leading-[inherit] fa-check text-[#673DEC]/80"></span>
                              <span className=" mt-2 pr-2 text-[#525F7F] font-semibold">Everything in Grow +</span>
                            </p>
                            <i
                              className={`fas text-sm ml-1 transform pl-4 !leading-[inherit] transition-all duration-300 ease-linear ${navDropdownOpen3 ? "fa-chevron-up" : "fa-chevron-down"
                                }`}>

                            </i>
                          </div>

                        </button>
                        <ul
                          className={`styled-list-checkmark-alt lg:grid lg:absolute border-[1px] block rounded-b-[8px]  left-0 py-4 px-2 mx-auto  drop-shadow-2xl transform  bg-white shadow-2x; text-left transition-all duration-300 ease-linear  ${navDropdownOpen3
                            ? "visible translate-y-0 opacity-100"
                            : "invisible -translate-y-4 opacity-0 hidden"
                            }`}
                        >
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Free account set up</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Support with onboarding and activation of integrations</span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Access to analytics on how your integration is performing
                          </span> </li>
                          <li> <span className="pr-2 text-[#525F7F]  font-semibold">Custom admin permission - control your users</span> </li>

                        </ul>
                        <ReactTooltip arrowColor="transparent" className=" common-shadow tooltip text-typography-heading" textColor="#01003A" backgroundColor="#fff" id="free">{"A task is any activity that is triggered to move data from one system to another."}</ReactTooltip>
                      </li>
                    </ul>

                  </div>
                  {/*END OF DROPDOWN 3 */}
                </div>
                <div className="w-full h-full">
                  <ul className="styled-list-checkmark-alt font-semibold p-4 space-y-4">
                    <li className="">
                      <span className=" mt-2 pr-2 text-[#525F7F] ">Unlimited tasks per month
                      </span>
                      <ReactTooltip arrowColor="transparent" className="tooltip text-typography-heading" textColor="#fff" backgroundColor="#01003A" id="grow">{"If you exceed your task limit for the month you will be notified and your account will move to the Platform Plan to continue service"}</ReactTooltip>
                    </li>
                    {pirceDropdown.platform.map((item) => (
                      <li key={item.name}>
                        <div
                          className="pr-2 font-semibold text-[#525F7F] "
                        >
                          <span> {item.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full flex-col flex justify-end">
                <div className="p-4">
                  <a className="text-white  text-center rounded-xl py-3 block  cursor-pointer no-underline hover:shadow-xl transiton-all ease-linear transition hover:text-white" href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=platform&version=5"
                      : "https://app.realsynch.com/register?plan=annualplatform&version=5"
                  }
                    outboundLink={true}
                    text="Choose Advanced"
                    style={{
                      background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #673DEC",
                    }}>
                    Sign up Now
                  </a>
                </div>
              </div>
            </div>
            {/* END OF GROW */}
          </div>
        </div>
        {/* END OF PRICE CARDS */}
      </section>

      {/* CTA 2 */}
      <section
        className="mt-12 pb-16 px-6 md:mt-0 md:pb-20 hidden md:block"
      >
        <div className="text-center text-typography-heading text-[20px]">
          <p className="leading-[30px]">
            Not sure what Plan is best for your business? <span className="pt-1 block">Schedule a call today with one of our team members.</span>
          </p>
          <ButtonSolid modal="modal-contact" text="Book a Consult" />
        </div>
      </section>
      {/* END OF CTA 2 */}
      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
